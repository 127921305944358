<template>
  <div class="jycfg-tb">
    <p class="title">
      <Select
        v-model="search_data.doType"
        size="small"
        style="width: 200px"
        @on-change="searchChange"
      >
        <Option :value="0">以太网AI通道</Option>
        <Option :value="1">以太网DO通道</Option>
      </Select>
      <Button v-if="CA('jybktd_add')" type="primary" size="small" @click="add"
        >添加通道</Button
      >
    </p>
    <x-table
      new-style
      :no-data-text="CA('jybktd_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :notHeight="true"
      size="small"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :label-width="100">
        <FormItem
          label="通道名称"
          prop="channelName"
          :rules="{ required: true, message: '请填写通道名称' }"
          v-if="form.guid"
        >
          <Input v-model="form.channelName" style="width: 300px"></Input>
        </FormItem>
        <FormItem
          label="通道类型"
          prop="doType"
          v-if="!form.guid"
          :rules="{ required: true, message: '请选择通道类型' }"
        >
          <Select v-model="form.doType" clearable style="width: 300px">
            <Option :value="0">以太网AI通道</Option>
            <Option :value="1">以太网DO通道</Option>
          </Select>
        </FormItem>
        <FormItem
          label="通道号"
          prop="beginNo"
          v-if="!form.guid"
          :rules="
            ({ required: true, message: '请输入通道号' },
            {
              validator(rule, value, callback) {
                if (!util.validateNumber(value)) {
                  callback('请填写正确的通道号');
                  return;
                }
                if (value > form.endNo) {
                  callback('起始通道号不能大于结束通道号');
                  return;
                }
                callback();
              },
            })
          "
        >
          <Input v-model="form.beginNo" style="width: 144px"></Input>~
          <Input v-model="form.endNo" style="width: 144px"></Input>
        </FormItem>
        <FormItem label="备注" prop="remark">
          <Input
            v-model="form.remark"
            type="textarea"
            :rows="3"
            style="width: 300px"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  props: {
    jyObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      util: CU,
      table: {
        columns: [
          {
            title: "通道名称",
            key: "channelName",
            minWidth: 100,
          },
          {
            title: "通道号",
            key: "channelNo",
            minWidth: 100,
          },
          {
            title: "通道类型",
            minWidth: 100,
            render: (h, { row }) => {
              return <span>{row.doType === 0 ? "AI通道" : "DO通道"}</span>;
            },
          },
          {
            title: "备注",
            key: "remark",
            minWidth: 100,
          },
          {
            title: "通道状态",
            key: "statusName",
            minWidth: 100,
          },
          {
            title: "操作",
            width: 100,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("jybktd_edit") && (
                    <a
                      class="iconfont"
                      title="编辑"
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      &#xe669;
                    </a>
                  )}
                  {this.CA("jybktd_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.guid)}
                    >
                      <a class="iconfont" title="删除">
                        &#xe66a;
                      </a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },

      search_data: {
        doType: 0,
        jycardId: "",
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },

      form: {
        beginNo: 0,
        doType: "",
        endNo: 0,
        remark: "",
        channelName: "",
      },
    };
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "添加通道",
        submitLoading: false,
      };
    },
    searchChange() {
      this.getList();
    },
    getList() {
      if (!this.CA('jybktd_check')) return
      this.table.loading = true;
      this.$post(this.$api.AISLE.JY_AISLELIST, {
        pageNo: 1,
        pageSize: 999,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.guid = row.guid;
      this.modal = {
        show: true,
        title: "编辑通道",
        submitLoading: false,
      };
    },
    del(guid) {
      this.$post(this.$api.AISLE.JY_AISLEDEL, {
        guid,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        beginNo: 0,
        doType: "",
        endNo: 0,
        remark: "",
        channelName: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.jycardId = this.jyObj.guid;
        params.jycardName = this.jyObj.name;
        if (params.guid) {
          delete params.beginNo;
          delete params.doType;
          delete params.endNo;
        }
        this.modal.submitLoading = true;
        this.$post(
          params.guid
            ? this.$api.AISLE.JY_AISLEEDIT
            : this.$api.AISLE.JY_AISLENEW,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.search_data.jycardId = this.jyObj.guid;
    this.getList();
  },
  watch: {
    jyObj: {
      handler(n) {
        this.search_data.jycardId = n.guid;
        this.getList();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.jycfg-tb {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
}
/deep/ .ivu-table-wrapper {
  height: 100%;
}

/deep/ .ivu-table.ivu-table-default {
  height: 100%;
}

/deep/ .ivu-table-header {
  top: 0;
  left: 0;
  height: 36.7px;
}

/deep/ .ivu-table-body {
  height: calc(100% - 36.7px);
  overflow-y: auto;
  z-index: 990;
}
</style>